'use client'

import { useEffect, useState } from 'react'
import Text from '@hiretalk/components/elements/typographies/text'
import Image from 'next/image'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { USER_LOGIN } from '@hiretalk/sdk'
import { useSearchParams } from 'next/navigation'
import Modal from '@hiretalk/components/layouts/modal'
import { toast } from 'react-hot-toast'
import Symbol from '@hiretalk/components/elements/symbol'
import { trackEvent } from '@hiretalk/libraries/trackingUtils'
import { XMarkIcon } from '@heroicons/react/24/outline'

type Props = {
  style?: 'GETTING_STARTED' | 'LOGIN'
}

const LoginForm: React.FC<Props> = ({ style = 'LOGIN' }) => {
  const [login] = useMutation(USER_LOGIN)

  const searchParams = useSearchParams()
  const redirect = searchParams.get('redirect')
  const [debounceCountdown, setDebounceCountdown] = useState(30)
  const [isDebounce, setIsDebounce] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    let timer: NodeJS.Timer

    if (isDebounce) {
      if (debounceCountdown > 0) {
        timer = setTimeout(() => {
          setDebounceCountdown((prevCountdown) => prevCountdown - 1)
        }, 1000)
      } else {
        setIsDebounce(false)
        setDebounceCountdown(30)
        setShowModal(false)
      }
    }

    return () => clearTimeout(timer)
  }, [debounceCountdown, isDebounce])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      setShowModal(true)
      setIsDebounce(true)
      try {
        toast.promise(
          login({
            variables: { email: values.email, redirect },
            onCompleted: () => {
              trackEvent('Login Link Requested', { email: values.email })
            },
          }),
          {
            loading: 'Logging in',
            success: 'Successfully Sent.',
            error: (error) => `This just happened. ${error.toString()}. Try again.`,
          },
        )
      } catch (err) {
        toast.error('An unexpected error occurred')
        setShowModal(false)
        setIsDebounce(false)
      }
    },
  })

  const getLoginForm = () => {
    if (style === 'GETTING_STARTED') {
      return (
        <div>
          <div className='flex items-center'>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='relative block  appearance-none rounded-l-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-leaf focus:outline-none focus:ring-leaf sm:text-sm'
              placeholder='Email address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <button
              type='submit'
              disabled={isDebounce}
              className='group relative  flex-nowrap justify-center rounded-r-md border border-transparent bg-leaf px-6 py-2 text-sm font-medium text-black text-white hover:bg-leaf focus:outline-none focus:ring-2 focus:ring-leaf focus:ring-offset-2'>
              Get Started
            </button>
          </div>
          <div className='mt-2 flex items-center'>
            <div className='flex items-center'>
              <Image src='/assets/images/icons/check.png' width={16} height={16} alt='Check icon' />
              <Text as='p' size='14' color='sky' className='ml-2'>
                No credit card required
              </Text>
            </div>
            <div className='ml-4 flex items-center'>
              <Image src='/assets/images/icons/check.png' width={16} height={16} alt='Check icon' />
              <Text as='p' size='14' color='sky' className='ml-2'>
                Free trial
              </Text>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <label htmlFor='email' className='sr-only'>
          Email address
        </label>
        <input
          id='email'
          name='email'
          type='email'
          autoComplete='email'
          required
          className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-leaf focus:outline-none focus:ring-leaf sm:text-sm'
          placeholder='Email address'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='mt-2 text-sm text-red-600'>{formik.errors.email}</div>
        ) : null}
        <div className='mt-4'>
          <button
            type='submit'
            disabled={isDebounce}
            className='group relative flex w-full justify-center rounded-md border border-transparent bg-mango px-4 py-2 text-sm font-medium text-black hover:bg-leaf focus:outline-none focus:ring-2 focus:ring-leaf focus:ring-offset-2'>
            {isDebounce ? `Try Resend Again in ${debounceCountdown} second(s)` : 'Send Login Link'}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
        <div className='rounded-md shadow-sm'>{getLoginForm()}</div>

        <Modal isOpen={showModal} onClose={() => setShowModal(false)} className='relative'>
          <div className='flex flex-col items-center gap-3 p-4 text-center'>
            <Image src='/assets/images/icons/check.png' width={70} height={70} alt='HireTalk logo' />
            <Text as='h1' size='20' color='green' weight='semibold'>
              Check your email for a special link to login to your account.
            </Text>
            <Text as='p' size='14' color='black' className='-mt-2'>
              This link will expire in 1 hour.
            </Text>
            <Text as='h2' size='20' color='sky' weight='semibold' className='mt-4'>
              Discover HireTalk
            </Text>
            <Text as='p' size='14' color='black' className='text-left'>
              HireTalk revolutionizes your hiring process. Easily set up campaigns, craft pertinent questions, and
              utilize cutting-edge AI to evaluate candidates. With HireTalk, applicants respond to interview questions
              vocally, and our system handles the scoring.
            </Text>
            <Text as='p' size='14' color='black' className='text-left'>
              <strong>Key Features:</strong>
              <ul className='list-inside list-disc'>
                <li>Simple Campaign Setup</li>
                <li>Automated Question Generation</li>
                <li>Detailed Candidate Progression</li>
                <li>Collaborative Scoring and Review</li>
                <li>Automation Features</li>
                <li>Live Interview Dashboard</li>
                <li>Anonymous Initial Review for DEI</li>
              </ul>
            </Text>
            <Text as='p' size='14' color='black' className='text-left'>
              For more information, visit our{' '}
              <a href='/#features' className='text-blue-500 underline' onClick={() => setShowModal(false)}>
                features page
              </a>
              .
            </Text>
            <Symbol
              Icon={XMarkIcon}
              size='small'
              theme='gray'
              className='absolute right-3 top-4 cursor-pointer transition-all duration-300 hover:scale-110'
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal>
      </form>
    </div>
  )
}

export default LoginForm
