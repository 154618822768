import { type VariantProps, cva } from 'class-variance-authority'

const style = cva(
  'rounded-md flex transform flex-col gap-4 shadow-md transition duration-300 ease-out border border-gray-800',
  {
    variants: {
      width: {
        small: 'w-full max-w-sm',
        medium: 'w-full max-w-md',
        large: 'w-full max-w-2xl',
        extra: 'w-full max-w-4xl',
        mega: 'w-full max-w-6xl',
      },
      background: {
        white: 'bg-white',
        transparent: 'bg-transparent',
        black: 'bg-black',
        gray: 'bg-secondary-gray-dark',
      },
      volume: {
        base: 'p-8',
        none: 'p-0',
      },
    },
    defaultVariants: {
      width: 'large',
      background: 'white',
      volume: 'base',
    },
  },
)

export default style

export type Style = VariantProps<typeof style>
